import React, { useState } from "react";
import Description from "../Components/Description";
import SuccessATCModal from "../Components/SuccessATCModal";
import { ReactComponent as CartImage } from "../images/shopping_cart-24px.svg";

function Product({ album, addToCart }) {
  const [showDes, setShowDes] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const svgImg = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="9 -3 5 30" width="24" class="buy-button-cart" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path></svg>`;
  const hanldeClick = (e) => {
    e.target.outerHTML = `<button
    type="button"
    disabled="true"
  >
  ${svgImg}
    Item in Cart
  </button>`;
    addToCart(album);
    setShowAdd(true);
    setTimeout(() => {
      setShowAdd(false);
    }, 2000);
  };

  const handleImgClick = () => {
    setShowDes(true);
  };

  const closeDes = () => {
    setShowDes(false);
  };

  return (
    <div className="albumCont">
      <div className="album">
        <h2 className="title">{album.title}</h2>
        {showDes && (
          <Description
            closeDes={closeDes}
            addToCart={addToCart}
            album={album}
          />
        )}
        {showAdd && <SuccessATCModal product={album} />}
        <div className="imgCont">
          <img
            className="albumCover"
            src={album.image}
            onClick={handleImgClick}
            alt={`album art for ${album.title}`}
          />
        </div>
        <p>${album.price}</p>
        <button type="button" onClick={hanldeClick}>
          <CartImage
            className="buy-button-cart"
            fill="currentColor"
            viewBox="9 -3 5 30"
          />
          Add Album to Cart
        </button>
      </div>
    </div>
  );
}

export default Product;

import React from "react";

function Footer() {
  return (
    <footer>
      <small>&copy;TEKcamp productions All Rights Reserved</small>
    </footer>
  );
}

export default Footer;

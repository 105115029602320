import React from "react";
import CartItem from "../Components/CartItem";
import { Link, useHistory } from "react-router-dom";

function Cart({ resetCart, cart, removeItem, plus, minus }) {
  const [paid, setPaid] = React.useState(false);
  const [error, setError] = React.useState(null);

  let subtotal = 0;
  let tax = 0;
  let total = 0;

  cart.forEach((album) => (subtotal += album.price * album.quantity));

  tax = subtotal * 0.0825;

  total = tax + subtotal;

  const paypalRef = React.useRef();
  const history = useHistory();
  let descriptionInfo = "Enjoy listening to: ";
  cart.forEach((item, index) =>
    index + 1 === cart.length
      ? (descriptionInfo += `${item.title}`)
      : (descriptionInfo += `${item.title}, & `)
  );

  React.useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: descriptionInfo,
                amount: {
                  currency_code: "USD",
                  value: total.toFixed(2),
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          setPaid(true);
          console.log(order);

          resetCart();
          history.push("/payment-success");
        },
        onError: (err) => {
          setError(err);
          console.error(err);
        },
      })
      .render(paypalRef.current);
  }, []);

  return (
    <div id="cart">
      {error && <div>Uh oh, an error occured! {error.message}</div>}
      <h1>Shopping Cart</h1>
      {!cart.length && (
        <>
          <h1>Empty!</h1>
          <Link to="/buy-music">
            <h3>Start Shopping!</h3>
          </Link>
        </>
      )}
      {paid ? (
        <>
          <h1>Payment Successful!!</h1>
          <Link to="/buy-music">
            <h3>Continue Shopping</h3>
          </Link>
        </>
      ) : (
        cart.map((product) => (
          <CartItem
            plus={plus}
            minus={minus}
            removeItem={removeItem}
            product={product}
          />
        ))
      )}

      {cart.length && paid
        ? null
        : !!cart.length && (
            <>
              <h4>Subtotal: ${subtotal.toFixed(2)}</h4>
              <h5>Tax: ${tax.toFixed(2)}</h5>
              <h3>Total: ${total.toFixed(2)}</h3>
              <Link to="/buy-music">
                <h3>Continue Shopping?</h3>
              </Link>
              <div ref={paypalRef} />
            </>
          )}
    </div>
  );
}

export default Cart;

import React, { useState } from "react";
import Product from "../Components/Product";

function Music({ products, addToCart }) {
  const [value, setValue] = useState("");

  let filterProds = products.filter(
    (album) =>
      album.title.toLowerCase().includes(value.toLowerCase()) ||
      album.songs.some((song) =>
        song.title.toLowerCase().includes(value.toLowerCase())
      )
  );

  return (
    <div id="music">
      <div className="announcementBanner">
        <h3 className="announcementText">
          'Hello Young Lovers' Available NOW!
        </h3>
      </div>
      <div className="inputCont">
        <i className="fas fa-search" />
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="search by album or song title"
        />
      </div>
      <h1>Digital Music</h1>
      <p>Click Album Cover For Info</p>
      <div className="productCont">
        {!value ? (
          products.map((album) => (
            <Product addToCart={addToCart} album={album} />
          ))
        ) : filterProds.length === 0 ? (
          <h2>No matching titles</h2>
        ) : (
          filterProds.map((album) => (
            <Product addToCart={addToCart} album={album} />
          ))
        )}
      </div>
    </div>
  );
}

export default Music;

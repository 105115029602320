import React from "react";
import SuccessATCModal from "./SuccessATCModal";
import { ReactComponent as CartImage } from "../images/shopping_cart-24px.svg";

class Description extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdd: false,
    };
  }

  svgImg = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="9 -3 5 30" width="24" class="buy-button-cart" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path></svg>`;

  handleClick = (song) => (e) => {
    e.target.outerHTML = `<button
    type="button"
    disabled="true"
  >
  ${this.svgImg}
    Item in Cart
  </button>`;

    this.props.addToCart(song);
    this.setState({
      showAdd: true,
      song: song,
    });
    setTimeout(() => {
      this.setState({ showAdd: false });
    }, 2000);
  };
  render() {
    const { closeDes, album } = this.props;
    return (
      <div className="descriptionCont">
        <div className="description">
          <div id="closeCont" onClick={closeDes}>
            <div id="closeBtn">X</div>
          </div>
          <h2 className="title">{album.title}</h2>
          <img src={album.image} alt={`album art for ${album.title}`} />
          <div className="albumDetail">
            <p>Artist: {album.artist}</p>
            <p>Released: {album.released}</p>
            <p>Genre: {album.genre}</p>
          </div>
          <h4>Songs</h4>
          <ol>
            {album.songs.map((song) => (
              <li className="song">
                <p className="title">{song.title}</p>
                <p>${song.price}</p>
                <button
                  type="button"
                  value={song}
                  onClick={this.handleClick(song)}
                >
                  <CartImage
                    className="buy-button-cart"
                    fill="currentColor"
                    viewBox="9 -3 5 30"
                  />
                  Add to Cart
                </button>
              </li>
            ))}
          </ol>
          {this.state.showAdd && <SuccessATCModal product={this.state.song} />}
        </div>
      </div>
    );
  }
}

export default Description;

import React from "react";

function PaySuccess({ downloads }) {
  console.log(downloads);
  return (
    <div id="paySuccess">
      <h1 id="welcome-h1">Thank You For Your Purchase</h1>
      <h2> Download your songs below</h2>
      {downloads.map((product) => (
        <a
          href={product.mp3}
          rel="noreferrer"
          target="_blank"
          download={product.title}
        >
          <i class="fa fa-download"></i>
          {product.songs
            ? `Download Album '${product.title}'`
            : `Download Song '${product.title}'`}
        </a>
      ))}
    </div>
  );
}

export default PaySuccess;

import React from "react";
import davidImage from "../images/david-at-piano.jpg";
import { Link } from "react-router-dom";

function Welcome() {
  return (
    <div className="welcome">
      <h1 id="welcome-h1">David Slater Music</h1>
      <img id="david-image" src={davidImage} alt="David Slater singing face" />
      {/* <h1 id="welcome-h1">Coming Soon</h1> */}
      <Link className="arrow" to="/buy-music"></Link>
    </div>
  );
}

export default Welcome;
